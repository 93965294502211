<template>
    <v-card class="pa-0">
        <v-toolbar short flat color="white">
            <v-toolbar-title class="green--text">{{label}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="green" @click="onClickCreateOrganization" v-if="isCreateEnabled">
                <font-awesome-icon icon="plus" style="font-size: 20px;" fixed-width></font-awesome-icon>
            </v-btn>
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-list dense nav>
            <v-list-item v-if="list.length === 0">No organizations yet.</v-list-item>
            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                <v-list-item-content>
                    <OrganizationListItem :attr="item" :link="!selectOne"></OrganizationListItem>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-row justify="center" class="py-5" v-show="displayCreateOrganization">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <v-card elevation="4" class="px-10 pb-5 pt-8">
                <v-form v-model="createOrganizationForm" ref="createOrganizationFormRef" @submit="validateCreateOrganization" onSubmit="return false;" @keyup.enter.native="validateCreateOrganization">
                    <div v-if="add">
                        <v-select :items="addOrganizationChoices" v-model="addOrganizationId" label="Select an organization"></v-select>
                    </div>
                    <div v-if="create">
                        <!--
                        <v-text-field
                            v-model=name
                            label="Name"
                            :rules="nameRules"
                            validate-on-blur
                            color="green"
                            required
                            hint="What should we call you?"
                            autofocus
                            outlined
                        >
                                            <template v-slot:prepend>
                        <font-awesome-icon icon="organization" class="mt-1" fixed-width></font-awesome-icon>
                    </template>
                        </v-text-field>
                        -->
                        <v-text-field
                            ref="nameInputRef"
                            v-model=newOrganizationName
                            label="Display Name"
                            :rules="newOrganizationNameRules"
                            validate-on-blur
                            color="green"
                            required
                            hint="The name for the organization"
                            type="text"
                            outlined
                        >
                        </v-text-field>

                    </div>
                    <v-row justify="center">
                        <v-card-actions>
                            <v-btn elevation="4" class="green white--text" @click="addOrganization" :disabled="!createOrganizationForm" v-if="add">
                                <font-awesome-icon icon="check" fixed-width></font-awesome-icon>
                                <span class="ml-2">Add</span>
                            </v-btn>
                            <v-btn elevation="4" class="green white--text" @click="createOrganization" :disabled="!createOrganizationForm" v-if="create">
                                <font-awesome-icon icon="check" fixed-width></font-awesome-icon>
                                <span class="ml-2">Create</span>
                            </v-btn>
                        </v-card-actions>
                    </v-row>
                </v-form>
            </v-card>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapState } from 'vuex';
import OrganizationListItem from '@/components/list-item/OrganizationListItem.vue';
import { isValidName, compact } from '@/sdk/input';

export default {
    components: {
        OrganizationListItem,
    },

    props: {
        // optional; use this when you want to use the organization list to select an organization; emits 'selected' when user taps on an organization
        selectOne: {
            type: Boolean,
            default: false,
        },
        // optional; true means the 'plus' button will be for adding a user to a list
        add: {
            type: Boolean,
            default: false,
        },
        // optional; true means the 'plus' button will be allowed to create new user records if they don't already exist
        create: {
            type: Boolean,
            default: false,
        },
        createRoute: {
            type: Object,
            default: null,
        },
        // policyId is optional; when provided, the create organization dialog functions as an 'add or create' dialog and allows user to choose from an existing organization to add to the list, or create a new organization
        policyId: {
            type: String,
        },
        list: {
            type: Array,
        },
    },

    data() {
        return {
            // list: [],
            // create organization
            displayCreateOrganization: false,
            createOrganizationForm: null,
            newOrganizationName: null,
            newOrganizationNameRules: [
                (v) => !!v || 'Display name is required',
                (v) => !v || isValidName(compact(v)) || 'Organization name is required',
            ],
            // add organization
            addOrganizationChoices: [],
            addOrganizationId: null,
        };
    },

    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        id() {
            return this.policyId;
        },
        label() {
            if (this.selectOne) {
                return 'Select an organization';
            }
            switch (this.list.length) {
            case 0:
                return 'No organizations';
            case 1:
                return '1 organization';
            default:
                return `${this.list.length} organizations`;
            }
        },
        isCreateEnabled() {
            return this.add || this.create || this.createRoute;
        },
    },

    methods: {
        onClickItem(organizationId) {
            // if (this.selectOne) {
            //     this.$emit('selected', { organizationId });
            // }
            this.$router.push({ name: 'organization-dashboard', params: { organizationId } });
        },
        onClickCreateOrganization() {
            if (typeof this.createRoute === 'object') {
                this.$router.push(this.createRoute);
            } else {
                this.displayCreateOrganization = true;
            }
        },
        async createOrganization() {
            this.error = false;
            console.log('createOrganization');
            const request = {
                // name: this.name,
                name: this.newOrganizationName,
                // agreeToTerms: this.isAgreeToTermsChecked,
                // interactionId: this.interactionId, // will be present if organization arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the organization to an appropriate location after organization is created
            };
            const response = await this.$client.user(this.user.id).user.createOrganization(request);
            console.log('createOrganization response: %o', response);
            const { isCreated, id, error } = response;
            if (isCreated) {
                const organization = { id, ...request };
                this.list.push(organization);
                this.$emit('created-organization', organization);
                this.$emit('added-organization', organization);
                this.displayCreateOrganization = false;
                this.newOrganizationName = null;
            } else if (error) {
                this.error = error;
            } else {
                this.error = 'Request failed';
            }
        },
        validateCreateOrganization() {
            if (this.$refs.createOrganizationFormRef.validate()) {
                this.createOrganization();
            }
        },

        async addOrganization() {
            const organization = { id: this.addOrganizationId, label: this.addOrganizationChoices.find((item) => item.value === this.addOrganizationId).text };
            this.list.push(organization);
            this.$emit('added-organization', organization);
            this.displayCreateOrganization = false;
            this.addOrganizationId = null;
        },
        async initAddOrganizationChoices() {
            // create a map of which organization ids are already in the list
            const organizationMap = {};
            this.list.forEach((item) => {
                organizationMap[item.id] = true;
            });
            // when we show the add/create organization dialog, load the list of available organizations to add to the policy (list of all organizations less the ones already in the policy)
            const result = await this.$client.user(this.user.id).user.getOrganizationList();
            console.log(`OrganizationViewList.vue fetch organizations result: ${JSON.stringify(result)}`);
            if (result && result.list) {
                // filter the results to remove organizations already in the policy, then convert the available organizations to a choice list for the v-select [ { text, value }, ... ]
                this.addOrganizationChoices = result.list.filter((item) => !organizationMap[item.id]).map((item) => ({ text: item.name, value: item.id }));
            }
        },
    },

    watch: {
        displayCreateOrganization(value) {
            if (value && (this.add || this.create)) {
                this.initAddOrganizationChoices();
            }
        },
    },
};
</script>
